<template>
  <form @submit.prevent="save" class="row d-flex justify-content-center">
    <div class="col">
      <h1>
        Create your team
      </h1>

      <div class="row mt-5">
        <div class="col input-group" :class="{'invalid': $v.model.teamName.$error }">
          <b-input
            type="text"
            data-testid="signupTeamNameField"
            v-focus
            class="bordered"
            v-model.trim="$v.model.teamName.$model"
            lazy-formatter
            id="company-input"
            aria-label="Team Name"
            placeholder="Team Name"/>
          <Error>
            <div>
              <span v-if="!$v.model.teamName.required">Team name is a required field</span>
              <span v-if="!$v.model.teamName.minLength">
                Team name must be at least
                 {{ $v.model.teamName.$params.minLength.min}} characters
              </span>
            </div>
          </Error>
        </div>
      </div>

      <div class="row">
        <div class="col mt-5">
          <Button
            type="submit"
            data-testid="signupSubmitTeamNameButton"
            :disabled="!isValid"
            class="primary lg bg-orange">
            Save & Continue
          </Button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'SignupAccountInfo',
  mixins: [validationMixin],

  props: {
    value: { type: Object, required: true },
    trialMode: { type: Boolean, default: false },
    userId: { type: String, default: '' },
  },

  data() {
    return {
      model: this.value,
    };
  },

  validations: {
    model: {
      teamName: { required, minLength: minLength(2) },
    },
  },

  computed: {
    isValid() {
      return !this.$v.$invalid;
    },
  },

  watch: {
    model: {
      deep: true,
      handler() {
        this.$emit('input', this.model);
      },
    },
  },

  methods: {
    trackLead() {
      const userData = {
        name: this.model.contactName,
        teamName: this.model.teamName,
        email: this.model.email,
      };

      const integrations = { integrations: { All: true, Intercom: false } };

      window.analytics.identify(
        this.userId,
        { ...userData },
        integrations,
      );
    },

    save() {
      this.trackLead();
      this.$emit('save');
    },
  },

};
</script>

<style scoped>
  .input-group {
    padding-top: 0;
  }
</style>
