<template>
  <form @submit.prevent="save" class="row d-flex justify-content-center">
    <div class="col">
      <h1>Thanks! We care a lot about security and privacy.
        We've sent a 6-digit verification code to your email.</h1>
      <h4 class="mt-4">Please enter your 6-digit code here.</h4>

      <ConfirmCodeInput
        data-testid="signupConfirmCodeField"
        @confirm="confirmCode"
        @resend="resendCode"
        :is-loading="isLoading"
        :is-resending="isResending" />
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';

import authService from '@/services/auth';
import signupService from '@/services/signup';

import ConfirmCodeInput from '@/components/Forms/ConfirmCodeInput.vue';

export default {
  name: 'SignupConfirmEmail',
  mixins: [validationMixin],

  components: {
    ConfirmCodeInput,
  },

  props: {
    email: { type: String, required: true },
  },

  data() {
    return {
      isLoading: false,
      isResending: false,
    };
  },

  methods: {
    async confirmCode(code) {
      this.isLoading = true;
      setTimeout(async () => {
        try {
          await authService.validateCode({ email: this.email, code, isSignup: true });
          this.$store.dispatch('successToast', 'Your email has been verified');

          this.$emit('save');
        } catch (err) {
          this.$store.dispatch(
            'errorToast',
            'Your verification code is invalid, please double check it or click resend',
          );
        } finally {
          this.isLoading = false;
        }
      }, 1000);
    },

    async resendCode() {
      this.isResending = true;
      try {
        await signupService.beginSignup(this.email);
      } catch (err) {
        this.$store.dispatch(
          'errorToast',
          'Something went wrong, we are not able to send the verification code',
        );
      } finally {
        this.isResending = false;
      }
    },
  },
};
</script>

<style scoped>
.input-group {
  padding-top: 0;
}
</style>
