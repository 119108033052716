<template>
  <div class="row d-flex align-items-center justify-content-center flex-column">
    <div class="col-12 col-lg-6">

      <div class="d-flex justify-content-center mt-7">
        <input
          type="number"
          data-index="0"
          ref="code0"
          :class="{active: !code.length }"
          :value="code[0]"
          @paste.prevent
          aria-label="Code digit 1"
          pattern="[0-9]*"/>
        <input
          type="number"
          data-index="1"
          ref="code1"
          :class="{active: code.length === 1 }"
          :value="code[1]"
          @paste.prevent
          aria-label="Code digit 2"
          pattern="[0-9]*"/>
        <input
          type="number"
          data-index="2"
          ref="code2"
          :class="{active: code.length === 2 }"
          :value="code[2]"
          @paste.prevent
          aria-label="Code digit 3"
          pattern="[0-9]*"/>
        <input
          type="number"
          data-index="3"
          ref="code3"
          :class="{active: code.length === 3 }"
          :value="code[3]"
          @paste.prevent
          aria-label="Code digit 4"
          pattern="[0-9]*"/>
        <input
          type="number"
          data-index="4"
          ref="code4"
          :class="{active: code.length === 4 }"
          :value="code[4]"
          @paste.prevent
          aria-label="Code digit 5"
          pattern="[0-9]*"/>
        <input
          type="number"
          data-index="5"
          ref="code5"
          :class="{active: code.length === 5 || code.length === 6 }"
          :value="code[5]"
          @paste.prevent
          aria-label="Code digit 6"
          pattern="[0-9]*"/>
      </div>

      <div class="row mt-5" v-if="isLoading">
        <div class="col">
          <Spinner class="ml-auto mr-auto"/>
        </div>
      </div>

      <div class="row mt-7">
        <div class="col text-center">
          <Button :is-loading="isResending" @click.native.prevent="resendCode" class="tertiary">
            Resend Code
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

const isNumber = /^[0-9]$/i;

export default {
  name: 'ConfirmCodeView',

  components: { Spinner },

  props: {
    isLoading: { type: Boolean, default: false },
    isResending: { type: Boolean, default: false },
  },

  data() {
    return {
      code: [],
    };
  },

  watch: {
    code() {
      if (this.isCodeFormatValid()) {
        this.$emit('confirm', this.code.join(''));
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener('paste', this.manualPasteCode);
    window.removeEventListener('keydown', this.handleCode);
  },

  mounted() {
    this.$refs.code0.focus();
    window.addEventListener('paste', this.manualPasteCode);
    window.addEventListener('keydown', this.handleCode);
  },

  methods: {
    isCodeFormatValid() {
      if (this.code.length < 6) { return false; }

      let isValid = true;

      for (let i = 0; i < this.code.length; i += 1) {
        if (!this.code[i]) {
          isValid = false;
        }
      }

      return isValid;
    },

    handleCode(e) {
      if (this.isLoading) {
        e.preventDefault();
        return;
      }

      if (e.key.toLowerCase() === 'backspace' && this.code.length) {
        this.code.pop();
        e.preventDefault();
        return;
      }

      if (isNumber.test(e.key) && !this.code.length < 6) {
        this.code.push(e.key);
        e.preventDefault();
      }
    },

    setCode(code) {
      const isCodeValid = code && code.length === 6 && !Number.isNaN(parseInt(code, 10)) && this.code.join('') !== code;
      if (isCodeValid) {
        this.code = [...code];
      }
    },

    manualPasteCode(e) {
      const text = (e.clipboardData || window.clipboardData).getData('text');
      this.setCode(text);
      if (!this.code.length) {
        this.$store.dispatch('errorToast', 'The code you are trying to paste does not have a valid format.');
      }
      e.preventDefault();
    },

    restrictUpdate(e) {
      if (e.key.toLowerCase() === 'backspace') {
        return;
      }

      if (e.target.value) {
        e.preventDefault();
      }
    },

    resendCode() {
      this.$emit('resend');
    },
  },
};
</script>

<style lang="less" scoped>

input[type='number'] {
  font-family: 'Avenir';
  font-size: 6rem;
  max-width: 50px;
  min-width: 35px;
  height: 75px;
  background: transparent;
  color: @green;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: normal;
  border-bottom: 2px solid @medium-navy;
  text-align: center;
  padding: 1rem 0 1.2rem 0;
  margin-left: 1.8rem;

  caret-color: transparent;

  &:first-of-type { margin-left: 0; }

  &.active {
    border-bottom: 3px solid @green;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
