<template>
  <form @submit.prevent="save" class="row d-flex justify-content-center">
    <div class="col">
      <h1>
        Create your account
      </h1>
      <div class="row mt-5">
        <div class="col input-group" :class="{'invalid': $v.model.firstName.$error }">
          <b-input
            type="text"
            class="bordered"
            v-focus
            v-model.trim="$v.model.firstName.$model"
            lazy-formatter
            id="first-name-input"
            data-testid="signupFirstNameField"
            aria-label="First Name"
            placeholder="First Name"/>
          <Error>
            <div>
              <span v-if="!$v.model.firstName.required">First Name is a required field</span>
              <span v-if="!$v.model.firstName.minLength">
                First Name must be at least {{ $v.model.firstName.$params.minLength.min}} characters
              </span>
            </div>
          </Error>
        </div>
      </div>

      <div class="row">
        <div class="col input-group" :class="{'invalid': $v.model.lastName.$error }">
          <b-input
            type="text"
            class="bordered"
            v-model.trim="$v.model.lastName.$model"
            lazy-formatter
            id="last-name-input"
            data-testid="signupLastNameField"
            aria-label="Last Name"
            placeholder="Last Name"/>
          <Error>
            <div>
              <span v-if="!$v.model.lastName.required">Last name is a required field</span>
              <span v-if="!$v.model.lastName.minLength">
                Name must be at least {{ $v.model.firstName.$params.minLength.min}} characters
              </span>
            </div>
          </Error>
        </div>
      </div>

      <div class="row">
        <div class="col input-group" :class="{'invalid': $v.model.password.$error }">
          <b-input
            type="password"
            class="bordered"
            v-model.trim="$v.model.password.$model"
            lazy-formatter
            id="password-input"
            data-testid="signupPasswordField"
            aria-label="Password"
            placeholder="Password"/>
          <Error>
            <div>
              <span v-if="!$v.model.password.required">Password is required</span>
              <span v-else-if="!$v.model.password.password">
                Your password is not secure enough, check the requirements below.
              </span>
            </div>
          </Error>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <PasswordTip color="navy" class="mt-5" />
        </div>
      </div>

      <div class="row">
        <div class="col mt-5">
          <Button
            type="submit"
            :disabled="!isValid"
            data-testid="signupSubmitUserInfoButton"
            class="primary lg bg-orange">
            Save & Continue
          </Button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

import { password } from '@/utils/custom-validators';
import PasswordTip from '@/components/Password/PasswordTip.vue';

export default {
  name: 'SignupCreateAccount',
  mixins: [validationMixin],

  components: { PasswordTip },

  props: {
    value: { type: Object, required: true },
    trialMode: { type: Boolean, default: false },
  },

  data() {
    return {
      model: this.value,
    };
  },

  validations: {
    model: {
      firstName: { required, minLength: minLength(2) },
      lastName: { required, minLength: minLength(2) },
      password: { required, password },
    },
  },

  computed: {
    isValid() {
      return !this.$v.$invalid;
    },
  },

  watch: {
    model: {
      deep: true,
      handler() {
        this.$emit('input', this.model);
      },
    },
  },

  methods: {
    save() {
      this.$emit('save');
    },
  },

};
</script>

<style scoped>
  .input-group {
    padding-top: 0;
  }
</style>
