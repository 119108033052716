<template>
  <section class="row w-100 align-self-end m-5 bg-tan">
    <div class="col align-self-center">
      <p class="text-center" v-if="text">{{text}}</p>
      <q class="d-block mt-4" v-if="quote" v-html="quote"></q>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SignupQuote',

  props: {
    step: { type: [Number, Boolean], required: true },
  },

  computed: {
    text() {
      switch (this.step) {
        case 2: return 'Balloon decreases meeting time by 70%.';
        case 4: return "Companies that promote creative collaboration are 5x more likely to be high-performing." // eslint-disable-line
        case 7: return "More than 75% of an employee’s day is spent communicating with colleagues through traditional avenues, leaving less than two hours for productive work." // eslint-disable-line
        default: return '';
      }
    },

    quote() {
      switch (this.step) {
        case 3: return "Balloon really showed me the power of giving your team a safe space. <br/><br/><b>Bob Wilkinson</b>, AWS" // eslint-disable-line
        case 5: return "The opportunities to use Balloon to improve your process and make people feel heard and valued are innumerable. I can't recommend it enough. <br/><br/><b>Matt Burke</b>, Stripe" // eslint-disable-line
        case 6: return "The ROI you'll get with Balloon is huge. And all you have to do is take a few minutes to sign up. <br/><br/><b>Judy Doherty</b>, Dartmouth College" // eslint-disable-line
        case false: return "Balloon has been more than just beneficial from a business standpoint. It’s helping us find solidarity and camaraderie, and it’s starting the conversations we need to thrive. <br/><br/><b>Anonymous Participant</b>, AWS CloudWatch Manager" // eslint-disable-line
        default: return '';
      }
    },
  },
};
</script>

<style lang="less" scoped>
section {
  opacity: .9;
  padding: 2.5rem;

  & p, q {
    color: @green;
    font-weight: 500;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  q {
    font-size: 1.5rem;
    line-height: 2rem;

    &:before {
      content: '"';
      font-size: 3.6rem;
      font-style: italic;
      font-weight: 600;
      line-height: 1.2rem;
      display: block;
      margin-left: -0.5rem;
    }
  }
}
</style>
