import Vue from 'vue';
import stripe from '@/services/stripe';
import { getUTMParams, getTemplateAuthorReferral } from '@/utils/localStorage';

export default {
  async beginSignup(email) {
    const utmParams = getUTMParams();
    try {
      const response = await Vue.axios.post(`api/auth/signup${utmParams}`, { email });

      return response.data;
    } catch (err) {
      throw Error(`API error at signup.beginSignup: ${err.message}`);
    }
  },

  async completeSignup(card, customer, token) {
    const utmParams = getUTMParams();
    const taReferral = getTemplateAuthorReferral();
    try {
      let stripeSource = null;

      if (card) {
        const { source } = await stripe.createSource(card);
        stripeSource = source;
      }

      const headers = { Authorization: `Bearer ${token}` };

      const response = await Vue.axios.patch(`api/auth/signup${utmParams}`, {
        ...customer,
        stripeSource: stripeSource?.id,
        referralAuthor: taReferral,
      }, { headers });

      const sessionToken = response.headers.authorization;
      sessionToken.replace('Bearer ', '');
      return { user: response.data, token: sessionToken };
    } catch (err) {
      throw Error(`API error at signup.completeSignup: ${err.message}`);
    }
  },
};
