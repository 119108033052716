<template>
  <section class="container d-flex flex-column justify-content-between">
    <a id="logo" href="https://getballoon.com">
      <icon width="140" height="30" name="balloon" color="tan" />
    </a>
    <div class="row justify-content-center">
      <div class="col col-md-10">
        <h1 class="my-5">Work faster,<br/> smarter, tighter.</h1>
        <h4 class="my-5">With Balloon, leaders can surface true data, boost productivity,
          and be informed by all voices, resulting in better decisions,
          faster&mdash;no meetings required.</h4>

        <h5 class="teal text-uppercase mb-4 mt-7 mb-5">All plans come with</h5>
        <h3 class="my-4 d-flex align-items-baseline">
          <icon name="checkbox_complete" color="teal" width="15" height="15" />
          Unlimited Flights, which enable teams to solve problems together
        </h3>
        <h3 class="my-4 d-flex align-items-baseline">
          <icon name="checkbox_complete" color="teal" width="15" height="15" />
          Unlimited Balloons, our unique collaboration feature
        </h3>
        <h3 class="my-4 d-flex align-items-baseline">
          <icon name="checkbox_complete" color="teal" width="15" height="15" />
          Access to expert-crafted Flight Templates
        </h3>
        <h3 class="my-4 d-flex align-items-baseline">
          <icon name="checkbox_complete" color="teal" width="15" height="15" />
          Exportable results
        </h3>
        <h3 class="my-4 d-flex align-items-baseline">
          <icon name="checkbox_complete" color="teal" width="15" height="15" />
          24/7 email and chat support
        </h3>
      </div>
    </div>
    <div class="row justify-content-center mt-5 company-logos">
      <div class="col text-center">
        <img :src="'images/companies/amazon-navy.svg'" alt="Amazon logo" />
      </div>
      <div class="col text-center">
        <img :src="'images/companies/google-navy.svg'" alt="Google logo" />
      </div>
      <div class="col text-center">
        <img :src="'images/companies/masterclass-navy.svg'" alt="Masterclass logo" />
      </div>
      <div class="col text-center">
        <img :src="'images/companies/capital-one-navy.svg'" alt="Capital One logo" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SignupOnboarding',
};
</script>

<style lang="less" scoped>
section {
  background-color: #161637;
  padding: 4rem;

  & * {
    color: @tan;
  }

  h1 {
    font-family: Sanomat;
    font-size: 4.2rem;
    font-weight: 600;
    line-height: 5rem;
  }

  h4 { font-weight: 500 }

  h3, h5 {
    font-size: 1.6rem;
    font-weight: 500;

    svg {
      min-width: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

.company-logos {
  align-items: center;

  img {
    max-width: 100px;
    margin-bottom: 2rem;
    filter:
      invert()
      saturate(0.55)
      brightness(1.06)
      hue-rotate(-22deg);
  }
}
</style>
