<template>
  <div class="row d-flex">
    <div class="col">
      <p>
        Your payment of
        <b>${{ amount }}</b>
        {{ trialMode ? 'will not be charged until' : 'will be charged on' }}
        <b>{{ billingDate }}</b>.
      </p>

      <StripeCreditCard @set="setStripeCard" @valid="validateCreditCard" class="mt-4"/>

      <Button
        type="submit"
        data-testid="signupCreateAccountButton"
        :disabled="!isValid || isLoading || !onReady"
        :is-loading="isLoading"
        class="primary lg mt-5 bg-orange"
        @click="save">
        {{ trialMode ? 'Start Free trial' : 'Subscribe' }}
      </Button>
      <div class="row mt-4">
        <div class="col text-center">
          <a class="navy mr-2" href="https://getballoon.com/terms" target="_blank">
            Terms of Use
          </a>
          -
          <a class="navy ml-2" href="https://getballoon.com/privacy" target="_blank">
            Privacy
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StripeCreditCard from '@/components/Forms/StripeCreditCard.vue';

export default {
  name: 'SignupPayment',

  components: { StripeCreditCard },

  props: {
    isLoading: { type: Boolean, default: false },
    onReady: { type: Boolean, default: false },
    selectedPlan: { type: Object, required: true },
    trialMode: { type: Boolean, required: true },
    billingDate: { type: String, default: '' },
    amount: { type: Number, required: true },
  },

  data() {
    return {
      stripeCard: null,
      isCreditCardValid: false,
    };
  },

  computed: {
    isValid() {
      return this.isCreditCardValid;
    },
  },

  methods: {
    save() {
      this.$emit('save');
    },

    validateCreditCard(isValid) {
      this.isCreditCardValid = isValid;
    },

    setStripeCard(stripeCard) {
      this.stripeCard = stripeCard;
      this.$emit('set', stripeCard);
    },
  },
};
</script>

<style lang="less" scoped>
.subscription-input {
  border: solid 1px @light-navy;
  padding: 1.4rem;
}

a:hover {
  text-decoration: underline;
}
</style>
