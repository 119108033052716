<template>
  <main class="container-fluid">
    <div class="row">
      <div
        class="d-none d-md-flex justify-content-center col-5 p-0 illustration"
        :class="`step${currentStep}`"
        data-testid="signupStepWrapper">

        <SignupOnboarding v-if="currentStep === 1" />
        <SignupQuote v-else :step="!isSuccess && currentStep" />
      </div>

      <div class="col right">
        <div class="row">
          <div class="col-1">
            <button v-show="currentStep > 1 && !isSuccess" class="back-button" @click="prevStep" >
              <icon color="teal" name="back_arrow" width="24" height="24" />
            </button>
          </div>
          <div class="col-10 mt-8 text-center">
            <a id="logo" href="https://getballoon.com">
              <icon width="140" height="30" name="balloon" color="navy"
                class="d-inline d-md-none mb-5" />
            </a>
          </div>
        </div>

        <div class="row d-flex flex-column justify-content-center align-items-center mt-8">
          <LoadingBalloonAnimation
            v-if="currentStep === 1 && isLoading"
            width="100"
            text=""
            class="col col-md-6"/>

          <div v-else class="col col-md-6">
            <template v-if="!isSuccess">
              <SignupConfirmEmail
                v-if="currentStep === 1"
                v-model="accountInfo"
                @save="nextStep"/>

              <SignupConfirmCode
                v-if="currentStep === 2"
                :email="accountInfo.email"
                @save="nextStep"/>

              <SignupCreateTeam
                v-if="currentStep === 3"
                :user-id="userId"
                v-model="accountInfo"
                @save="nextStep"/>

              <SignupCreateAccount
                v-if="currentStep === 4"
                v-model="accountInfo"
                @save="nextStep"/>

              <SignupPlanConfirmation
                v-if="currentStep === 5"
                v-model="selectedPlan"
                :account-info="accountInfo"
                :plans="plans"
                :default-users="users"
                :is-loading="isLoading"
                :require-payment="requirePayment"
                :trial-mode="trialMode"
                :billing-date="billingDate"
                @users-change="setUsers"
                @set="setStripeCard"
                @save="signup"/>
            </template>

            <SignupSuccess v-if="isSuccess"/>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
import { requirePayment } from '@/config';

import { mapActions } from 'vuex';
import { format, addDays } from 'date-fns';

import signupService from '@/services/signup';
import { getUserIdFromToken } from '@/utils/userToken';
import subscriptionService from '@/services/subscription';
import { getPlan } from '@/utils/subscriptionPlans';

import SignupQuote from '@/components/Signup/SignupQuote.vue';
import SignupSuccess from '@/components/Signup/SignupSuccess.vue';
import SignupOnboarding from '@/components/Signup/SignupOnboarding.vue';
import SignupCreateAccount from '@/components/Signup/SignupCreateAccount.vue';
import SignupCreateTeam from '@/components/Signup/SignupCreateTeam.vue';
import SignupConfirmCode from '@/components/Signup/SignupConfirmCode.vue';
import SignupConfirmEmail from '@/components/Signup/SignupConfirmEmail.vue';
import SignupPlanConfirmation from '@/components/Signup/SignupPlanConfirmation.vue';

const LAST_STEP = 5;

export default {
  name: 'SignupView',
  components: {
    SignupCreateAccount,
    SignupCreateTeam,
    SignupConfirmCode,
    SignupConfirmEmail,
    SignupSuccess,
    SignupPlanConfirmation,
    SignupQuote,
    SignupOnboarding,
  },

  data() {
    return {
      accountInfo: {
        lastName: '',
        firstName: '',
        teamName: '',
        teamDomain: '',
        password: '',
        email: this.$route.query.email || '',
      },

      token: '',
      plans: [],
      selectedPlan: {},
      users: [],

      currentStep: parseInt(this.$route.query.step, 10) || 1,

      requirePayment,
      isLoading: false,
      isSuccess: false,
      stripeCard: null,
    };
  },

  computed: {
    selectedPlanHasTrial() {
      return !!this.selectedPlan?.trialDays;
    },

    trialMode() {
      // can force trial mode OFF (on front-end) by setting here:
      const forceNoTrial = false;
      return forceNoTrial ? false : this.selectedPlanHasTrial;
    },

    billingDate() {
      let now = new Date();
      if (this.trialMode && this.selectedPlan?.trialDays) {
        now = addDays(now, this.selectedPlan.trialDays);
      }
      return format(now, 'MMMM Do');
    },

    userId() {
      let id;
      try {
        id = getUserIdFromToken(this.token);
      } catch (e) {
        id = '';
      }
      return id;
    },
  },

  async created() {
    this.isLoading = true;
    this.coupon = this.$route.query.coupon || '';
    this.plans = await subscriptionService.getSubscriptionPlans();
    this.isLoading = false;
    this.selectedPlan = { ...getPlan(this.plans, 'pro', 'year') };
  },

  methods: {
    ...mapActions(['completeSignup']),

    setStripeCard(stripeCard) {
      this.stripeCard = stripeCard;
    },

    setUsers(users) {
      this.users = users;
    },

    async nextStep(token) {
      if (this.currentStep === 1) {
        this.$store.dispatch('logout');
      }
      if (token) {
        this.token = token;
      }

      if (this.currentStep === LAST_STEP - 1 && !this.requirePayment) {
        await this.signup();
      }

      this.currentStep += 1;
    },

    prevStep() {
      if (this.currentStep === 1) { return; }
      this.currentStep -= 1;
    },

    async signup() {
      try {
        this.isLoading = true;

        const signupPlans = {
          [this.selectedPlan.id]: {
            id: this.selectedPlan.id,
            amount: 1,
          },
        };

        const additionalFlightCreators = [];

        this.users.forEach((u) => {
          if (!signupPlans[u.planId]) {
            signupPlans[u.planId] = {
              amount: 1,
              id: u.planId,
            };
          } else {
            signupPlans[u.planId].amount += 1;
          }

          additionalFlightCreators.push({
            email: u.email,
            planId: u.planId,
          });
        });

        const authData = await signupService.completeSignup(
          this.stripeCard,
          {
            ...this.accountInfo,
            mainPlan: this.selectedPlan.id,
            plans: Object.values(signupPlans),
            additionalFlightCreators,
          },
          this.token,
        );

        // Send custom event for Google Ads
        if (window.dataLayer && window.dataLayer.length) {
          window.dataLayer.push(
            {
              event: 'userSignupCompleted',
              conversionValue: this.selectedPlan.amount / 100,
            },
          );
        }

        this.completeSignup(authData);
        this.isSuccess = true;
      } catch (err) {
        this.$store.dispatch('errorToast', 'There was a problem completing your Sign up.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.back-button {
  position: absolute;
  left: 24px;
  top: 50px;
}

.illustration {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: @navy;
  transition: all .5s ease-in;
}

.step2 {
  background-image: url(../../assets/illustrations/illustration-2.svg);
}

.step3 {
  background-image: url(../../assets/illustrations/illustration-3.svg);
}

.step4 {
  background-image: url(../../assets/illustrations/illustration-4.svg);
}

.step5 {
  background-image: url(../../assets/illustrations/illustration-7.svg);
}

.right {
  height: 100vh;
  overflow: scroll;
  padding-bottom: 5rem;
}
</style>
