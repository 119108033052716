<template>
  <div>
      <div class="row d-flex">
        <div class="col">
          <h1 class="mb-5">
            {{ trialMode ? 'Start your Balloon free trial' : 'Create your account' }}
          </h1>
          <h2>Add additional flight creators.</h2>

          <div class="row mt-4">
            <div class="col green">
              You can also add more flight creators later.
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <h5 class="green uppercase">flight creator email</h5>
            </div>
          </div>

          <div class="row mt-3">
            <div
              class="col col-md-9 input-group pt-0 d-flex flex-row
                justify-content-center align-items-center"
            >
              <icon class="mr-4" name="admin" color="green" />
              <b-input
                ref="current-email"
                type="text"
                class="bordered bold"
                :value="accountInfo.email"
                disabled
                aria-label="Current Email"
                placeholder="Current Email"/>
            </div>
          </div>

          <div
            class="row"
            v-for="(u, i) in $v.users.$each.$iter"
            :key="i">
            <div class="col">
              <div class="row">
                <div
                  class="col col-md-9 input-group d-flex flex-row
                    justify-content-center align-items-center"
                >
                  <button class="mr-4" @click="removeUser(i)">
                    <icon name="remove" height="7" width="7"/>
                  </button>
                  <b-input
                    ref="additional-email"
                    type="text"
                    :class="{'invalid': u.email.$error }"
                    v-model.trim="u.email.$model"
                    class="bordered"
                    aria-label="Email"
                    placeholder="Email"/>
                </div>
              </div>
              <div class="row" :class="{'invalid': u.email.$error }">
                <div class="col">
                  <Error class="red">
                    <span v-if="!u.email.required">Email is a required field</span>
                    <span v-else-if="!u.email.email">Not a valid email address</span>
                  </Error>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-show="hasDuplicateEmails" :class="{'invalid': hasDuplicateEmails }">
            <div class="col">
              <Error class="red">
              <span>There are duplicated email addresses</span>
              </Error>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <Button
                class="secondary d-flex justify-content-center align-items-center"
                @click.native="addUser"
                :disabled="!canAddUsers">
                <icon class="mr-4" name="add"/>
                Add Another Flight Creator
              </Button>
              <div class="row mt-3" v-show="!isValid" :class="{'invalid': !isValid }">
                <div class="col">
                  <Error class="red">
                    <span>Complete all flight creators correctly or remove them to continue</span>
                  </Error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row  mt-7">
        <div class="col-12">
          <h2>Confirm your billing info.</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mt-4">
          <p :class="{ 'invisible': !isAnnual }" class="text-center mb-2 bold teal">
            Save 25% by paying annually
          </p>
        </div>
      </div>
      <div class="row d-flex position-relative">
        <div class="col">
          <Button
            class="primary interval-button"
            :class="{ 'interval-button-disabled': !isAnnual }"
            @click.native="selectInterval('year')"
            >
            Pay Annually
          </Button>
        </div>
        <div class="col">
          <Button
            class="primary interval-button"
            :class="{ 'interval-button-disabled': isAnnual }"
            @click.native="selectInterval('month')"
            >
            Pay Monthly
          </Button>
        </div>
      </div>
      <div class="row d-flex ">
        <div class="col mt-3">
          <div class="pricing-header bg-green d-flex flex-column justify-content-center p-5">
            <h4 class="tan">Pricing</h4>
            <span class="teal mt-3">You Pay</span>
            <h2 class="teal mt-3">
              ${{ isAnnual ? Math.round(amount / 12) : amount}}
              <small>/ month</small>
            </h2>
          </div>

          <div
            class="pricing-body green bg-medium-tan d-flex justify-content-evenly flex-column p-5"
          >
            <h4 class="green">Bill Summary</h4>

            <table class="mt-4">
              <thead>
                <tr>
                  <th>Tier</th>
                  <th class="text-center"># Flight Creators</th>
                  <th class="text-right">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(f, k) in flightCreatorDetails" :key="k">
                  <td class="capitalize">{{ k }}</td>
                  <td class="text-center">{{  f.qty }}</td>
                  <td class="text-right">
                    ${{
                      isAnnual
                        ? Math.round(f.amount / 12)
                        : f.amount
                    }}/flight creator/mo
                    </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td class="text-right">
                    ${{ amount }} {{ model.interval }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <SignupPayment
        v-if="requirePayment"
        ref="payment-information"
        :is-loading="isLoading"
        :on-ready="isValid"
        :selected-plan="model"
        :trial-mode="trialMode"
        :billing-date="billingDate"
        :amount="amount"
        @set="set"
        @save="save"
        class="mt-7"
      />
      <Button
        v-else
        type="submit"
        :is-loading="isLoading"
        :disabled="!isValid"
        class="primary lg mt-7 bg-orange"
        @click="save">
        {{ buttonText }}
      </Button>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import SignupPayment from '@/components/Signup/SignupPayment.vue';
import {
  getPlan, getPlans, planAmount, isAnnualPlan,
} from '@/utils/subscriptionPlans';

export default {
  name: 'SignupPlanConfirmation',

  mixins: [validationMixin],

  components: { SignupPayment },

  props: {
    value: { type: Object, required: true },
    plans: { type: Array, required: true },
    trialMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    requirePayment: { type: Boolean, default: false },
    billingDate: { type: String, default: '' },
    accountInfo: { type: Object, required: true },
    defaultUsers: { type: Array, default: () => [] },
  },

  data() {
    return {
      model: this.value,
      users: [...this.defaultUsers],
    };
  },

  validations: {
    users: {
      $each: {
        email: {
          required,
          email,
        },
        tier: {
          required,
        },
      },
    },
  },

  computed: {
    buttonText() {
      return this.trialMode ? 'Start free trial' : 'Subscribe';
    },

    isValid() {
      if (this.hasDuplicateEmails) {
        return false;
      }

      return !this.$v.$invalid;
    },

    hasDuplicateEmails() {
      if (!this.users.length) {
        return false;
      }

      const emails = this.users.map((u) => u.email);
      emails.push(this.accountInfo.email);
      return (new Set(emails)).size !== emails.length;
    },

    limit() {
      return this.model.limit;
    },

    isAnnual() {
      return isAnnualPlan(this.model);
    },

    amount() {
      if (!this.users.length) {
        return this.model.amount / 100;
      }

      let totalAmount = 0;

      this.users.forEach((u) => {
        totalAmount += planAmount(this.plans, u.tier, this.model.interval);
      });

      return (this.model.amount + totalAmount) / 100;
    },

    flightCreatorDetails() {
      const creators = {
        [this.model.tier]: {
          amount: this.model.amount / 100,
          qty: 1,
        },
      };

      this.users.forEach((u) => {
        if (!creators[u.tier]) {
          creators[u.tier] = {
            amount: planAmount(this.plans, u.tier, this.model.interval) / 100,
            qty: 0,
          };
        }

        creators[u.tier].qty += 1;
      });

      return creators;
    },

    yearPlans() {
      return getPlans(this.plans, 'year');
    },

    canAddUsers() {
      if (!this.users.length) {
        return true;
      }

      const latestUser = this.users[this.users.length - 1];
      if (!latestUser.email) {
        return false;
      }

      return !this.isValid;
    },
  },

  watch: {
    model: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.interval !== oldVal.interval) {
          this.usersChange();
        }

        this.$emit('input', this.model);
      },
    },

    users: {
      deep: true,
      handler() {
        this.usersChange();
      },
    },
  },

  methods: {
    save() {
      this.$emit('save');
    },

    set(stripeCard) {
      this.$emit('set', stripeCard);
    },

    usersChange() {
      const users = this.users.map((u) => ({
        ...u,
        planId: getPlan(this.plans, u.tier, this.model.interval).id,
      }));
      this.$emit('users-change', users);
    },

    addUser() {
      if (!this.canAddUsers) {
        return;
      }

      this.users.push({
        email: '',
        tier: 'pro',
      });
    },

    removeUser(index) {
      this.users.splice(index, 1);
    },

    changePlan() {
      this.model = this.model.interval === 'year'
        ? getPlan(this.plans, this.model.tier, 'month')
        : getPlan(this.plans, this.model.tier, 'year');
    },

    selectInterval(interval) {
      this.model = getPlan(this.plans, this.model.tier, interval);
    },

    selectPlan(plan) {
      this.model = plan;
    },
  },

};
</script>

<style lang="less" scoped>
.pricing-header {
  min-height: 186px;
  h2 {
    line-height: 1;
    font-size: 4rem;
    font-weight: normal;
    small {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.69;
    }
}
}

.pricing-body {
  min-height: 251px;
}

.interval-button {
  width: 100%;
  color: @green;
  background: @teal;
  font-weight: 700;
  span {
    font-size: 2rem;
  }

}

.interval-button-disabled {
  background: white;
}
th {
  text-align: left;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.4;
  text-transform: none;
  letter-spacing: normal;
  color: @green;
}

td {
  // width: 50%;
  padding-top: 1.4rem;
  padding-bottom: 1.6rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

tfoot {
  td {
    font-weight: 900;
    padding-top: 1.6rem;
    border-top: 1px solid @green;
  }
}
</style>
