<template>
  <form @submit.prevent="save" class="row d-flex justify-content-center">
    <div class="col">
      <h1 class="my-5">Get started today.</h1>
      <h2>The first step is to verify your email address.</h2>
      <div class="row mt-5">
        <div class="col input-group" :class="{'invalid': $v.model.email.$error }">
          <b-input
            type="text"
            class="bordered"
            data-testid="signupConfirmEmailField"
            v-focus
            v-model.trim="$v.model.email.$model"
            lazy-formatter
            aria-label="Business Email Address"
            placeholder="Business Email Address"/>
          <Error>
            <div>
              <span v-if="!$v.model.email.required">Email is a required field</span>
              <span v-if="!$v.model.email.email">Not a valid email address</span>
            </div>
          </Error>
        </div>
      </div>

      <div class="row">
        <div class="col mt-5">
          <Button
            type="submit"
            :is-loading="isLoading"
            :disabled="!isValid"
            class="primary lg bg-orange"
            data-testid="submitSignupConfirmEmailButton">
            Get Started
          </Button>
          <BackToLoginLink color="navy"/>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email as emailValidation } from 'vuelidate/lib/validators';

import signupService from '@/services/signup';

export default {
  name: 'SignupConfirmEmail',
  mixins: [validationMixin],

  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      model: this.value,
      isLoading: false,
    };
  },

  validations: {
    model: {
      email: { required, emailValidation },
    },
  },

  computed: {
    isValid() {
      return !this.$v.$invalid;
    },
  },

  watch: {
    model: {
      deep: true,
      handler() {
        this.$emit('input', this.model);
      },
    },
  },

  methods: {
    trackLead() {
      const userData = {
        name: this.model.email,
      };

      const integrations = { integrations: { All: true, Intercom: false } };

      window.analytics.identify(
        { ...userData, email: this.model.email },
        integrations,
        () => {
          window.analytics.track('User Signup Started', {}, integrations);
          window.Intercom('update', { ...userData, anonymous_email: this.model.email });
          window.Intercom('trackEvent', 'User Signup Started');
        },
      );
    },

    async save() {
      this.isLoading = true;
      try {
        const { token } = await signupService.beginSignup(this.model.email);

        this.trackLead();
        this.$emit('save', token);
      } catch (err) {
        this.$store.dispatch(
          'errorToast',
          'Something went wrong, we are not able to send the verification code',
        );
      } finally {
        this.isLoading = false;
      }
    },
  },

};
</script>

<style scoped>
  .input-group {
    padding-top: 0;
  }

  h1 {
    font-family: Sanomat;
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 5rem;
  }

  h2 {
    font-size: 2.8rem;
    line-height: 4rem;
  }
</style>
